import * as React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"




const InfoTemplate = ({ data }) => {
  const { title, fullText, image} = data.info
  const image1 = getImage(image)


  return (
    <>
      <Seo title={title} />
      <Layout>
        <SimpleBanner >
         <GatsbyImage
            className="banner__image"
            image={image1}
            alt="Banner Image"
          />
        </SimpleBanner>
       
        
        <section>
          <BlogSingleStyles>
            <h1 className="blogsingle__title">{title}</h1>
           
            <article className="blogsingle__content">
            {renderRichText(fullText)}
              <div className="blogsingle__back">
              <Button text="Към Информация" as={Link} to="/infos" />
              </div>
            </article>
          </BlogSingleStyles>
        </section>
        
        
      </Layout>
    </>
  )
}

export const query = graphql`
    query($slug: String!) {
        info: contentfulInfo(slug: { eq: $slug }) {
            title
            introduction
            fullText {
                raw
            }
    	    image {
                gatsbyImageData(width: 2000, formats: [AUTO, WEBP])
            }
        }
    }
`

export default InfoTemplate
